var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("aside", { staticClass: "menu" }, [
        _c(
          "ul",
          _vm._l(_vm.tabs, function (tab) {
            return _c(
              "li",
              {
                key: tab.key,
                class: {
                  active: _vm.$router.currentRoute.meta.tab
                    ? _vm.$router.currentRoute.meta.tab.includes(tab.key)
                    : false,
                },
                on: { click: () => _vm.navigateTo(tab.route) },
              },
              [
                _c("span", [_vm._v(_vm._s(tab.label))]),
                _c("svgicon", {
                  staticClass: "triangle",
                  attrs: { name: "triangle" },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c(
        "transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }